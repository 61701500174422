import React from "react";
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AddGroupButton from "./group/addGroupButton";
import { Group } from "./group/group";
import { makeRequest } from "./requests";
import Loading from "./sharedComponents/loading";
import Task from "./task/task";
import Ungrouped from "./ungrouped";

type Props = {
    archived: boolean
}

type State = {
    enableGroupDragging: boolean
    groups?: Group[]
    archivedGroups?: Group[]
}

class GroupsView extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            enableGroupDragging: false
        };
        this.onAddGroup = this.onAddGroup.bind(this);
        this.onGroupUpdate = this.onGroupUpdate.bind(this);
        this.onGroupDeletion = this.onGroupDeletion.bind(this);
        this.onGroupArchive = this.onGroupArchive.bind(this);
        this.onTaskUpdate = this.onTaskUpdate.bind(this);
        this.onTaskDelete = this.onTaskDelete.bind(this);
        this.onNewTask = this.onNewTask.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onDragStart = this.onDragStart.bind(this);
        this.handleGroupDrag = this.handleGroupDrag.bind(this);
    }

    enableGroupDropping() {
        this.setState({enableGroupDragging: true});
    }

    disableGroupDropping() {
        this.setState({enableGroupDragging: false});
    }

    enableTaskDropping() {
        this.setTaskDropping(false);
    }

    disableTaskDropping() {
        this.setTaskDropping(true);
    }

    setTaskDropping(disabled: boolean) {
        let groups: Group[];
        if (this.props.archived) {
            groups = [...this.state.archivedGroups]
        } else {
            groups = [...this.state.groups]
        }
        for (let i = 0; i < groups.length; i++) {
            groups[i] = <Group {...groups[i].props} disableTaskDragging={disabled} /> as unknown as Group;
        }
        if (this.props.archived) {
            this.setState({archivedGroups: groups});
        } else {
            this.setState({groups: groups});
        }
    }

    onDragStart(result) {
        let draggableId = result.draggableId;
        // check if it is a group
        let groups: Group[];
        if (this.props.archived) {
            groups = this.state.archivedGroups;
        } else {
            groups = this.state.groups;
        }
        let isGroup: boolean = false;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].props.groupId === draggableId) {
                isGroup = true;
            }
        }
        if (isGroup) {
            this.enableGroupDropping();
            this.disableTaskDropping();
        } else {
            this.enableTaskDropping();
            this.disableGroupDropping();
        }
    }

    onDragEnd(result) {
        if (!result.destination) {
            // we dragged the task out of range of a group, ignore the drag
            return;
        }
        // check if it is a group or task being dragged
        let id = result.draggableId;
        for (let i = 0; i < this.state.groups.length; i++) {
            if (this.state.groups[i].props.groupId === id) {
                console.log("Dragging a group!");
                this.handleGroupDrag(result);
                return;
            }
        }
        let destinationGroupId = result.destination.droppableId;
        let sourceGroupId = result.source.droppableId;
        let destinationIndex = result.destination.index;
        let sourceIndex = result.source.index;
        let taskId = result.draggableId;

        let groups: Group[] = [...this.state.groups]
        // fetch the task that we are going to modify
        // this will be in the sorce group
        let task: Task = undefined;
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.props.groupId === sourceGroupId) {
                let tasks = group.props.tasks;
                for (let j = 0; j < tasks.length; j++) {
                    if (tasks[j].props.id === taskId) {
                        task = tasks[j];
                        break;
                    }
                }
            }
        }
        // remove task from source group
        groups = this.removeTask(taskId, groups);
        
        // find dest group
        let destinationGroup: Group = undefined;
        let destinationGroupIndex = undefined;
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.props.groupId === destinationGroupId) {
                destinationGroup = group;
                destinationGroupIndex = i;
                break;
            }
        }

        // if the task is completed, we need to move it down to at least
        // the first completed task (don't let us drag it into incomplete tasks)
        if (task.props.isComplete) {
            let groupTasks = destinationGroup.props.tasks;
            let firstComplete = groupTasks.length;
            for (let i = 0; i < groupTasks.length; i++) {
                if (groupTasks[i].props.isComplete) {
                    firstComplete = i;
                    break;
                }
            }
            destinationIndex = Math.max(destinationIndex, firstComplete);
        }
        
        let taskUpdates = []
        // update the moved task
        task = <Task
            {...task.props}
            renderPosition={destinationIndex}
            groupPosition={destinationIndex}
            groupId={destinationGroupId}
            color={destinationGroup.props.color}
        /> as unknown as Task;
        taskUpdates.push({
            taskID: task.props.id,
            update: {
                groupPosition: task.props.groupPosition,
                Group: task.props.groupId
            }
        });
        // update the other tasks in the destination group
        let destinationGroupTasks: any = [...destinationGroup.props.tasks];
        destinationGroupTasks.push(task);
        for (let i = 0; i < destinationGroupTasks.length; i++) {
            let task = destinationGroupTasks[i];
            if (task.props.id === taskId) {
                continue;
            }
            else {
                let updatedPosition = task.props.renderPosition;
                if (task.props.renderPosition > destinationIndex) {
                    updatedPosition = task.props.renderPosition + 1;
                } else if (task.props.renderPosition === destinationIndex) {
                    // determine if it moved up or down
                    if (sourceGroupId !== destinationGroupId) {
                        updatedPosition = task.props.renderPosition + 1;
                    } else if (sourceIndex > destinationIndex) {
                        updatedPosition = task.props.renderPosition + 1;
                    } else {
                        updatedPosition = task.props.renderPosition - 1;
                    }
                }
                task = <Task
                    {...task.props}
                    renderPosition={updatedPosition}
                    groupPosition={updatedPosition}
                />;
                destinationGroupTasks[i] = task;
                taskUpdates.push({
                    taskID: task.props.id,
                    update: {
                        groupPosition: task.props.groupPosition,
                    }
                });
            }
        }
        groups[destinationGroupIndex] = <Group
            {...destinationGroup.props}
            tasks={destinationGroupTasks}
        /> as unknown as Group;
        this.setState({groups: groups});
        makeRequest({action: "BatchUpdateTask", taskUpdates: taskUpdates});
    }

    handleGroupDrag(result) {
        let destinationIndex = result.destination.index;
        let sourceIndex = result.source.index;
        let draggedGroupId = result.draggableId;
        let groups: Group[];
        let updates = [];
        if (this.props.archived) {
            groups = [...this.state.archivedGroups];
        } else {
            groups = [...this.state.groups];
        }
        let draggedGroup: Group;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].props.groupId === draggedGroupId) {
                draggedGroup = groups[i];
                groups.splice(i, 1);
            }
        }
        groups.splice(destinationIndex, 0, draggedGroup);
        for (let i = 0; i < groups.length; i++) {
            let group = groups[i];
            if (group.props.groupId === "ungrouped") {
                continue;
            }
            updates.push({groupID: group.props.groupId, position: i});
            groups[i] = <Group {...group.props} position={i} index={i} /> as unknown as Group;
        }
        makeRequest({action: "batchupdategroup", groupUpdates: updates});
        if (this.props.archived) {
            this.setState({archivedGroups: groups});
        } else {
            this.setState({groups: groups});
        }
        this.forceUpdate();
    }

    async onAddGroup() {
        // TODO - can add a way to enter a name before this request
        let groupJson = await makeRequest({
            action: "AddGroup",
            name: "Enter a group name"
        });
        let group = this.groupFromJson(groupJson);
        this.addGroup(group);
    }

    onGroupArchive(groupId: string, archived: boolean) {
        if ((this.props.archived && !archived) ||
         (!this.props.archived && archived)) {
            this.removeGroup(groupId);
        }
    }

    onGroupDeletion(groupId: string) {
        this.removeGroup(groupId);
    }

    onGroupUpdate(group: Group) {
        this.removeGroup(group.props.groupId);
        this.addGroup(group);
    }

    onNewTask(taskJson) {
        let task = Task.createTaskFromJson(
            taskJson, taskJson.groupPosition, this.onTaskUpdate, this.onTaskDelete,
            undefined, undefined);
        let groups = this.addTask(task, [...this.state.groups]);
        this.setState({groups: groups});
    }

    onTaskUpdate(task: Task) {
        let groups = [...this.state.groups];
        // find original task and remove it
        groups = this.removeTask(task.props.id, groups);
        // add the updated task to the correct group
        groups = this.addTask(task, groups);
        this.setState({groups: groups});
    }

    onTaskDelete(taskId: string) {
        let groups = [...this.state.groups];
        groups = this.removeTask(taskId, groups);
        this.setState({groups: groups});
    }

    /**
     * Add a task to the groups and return the modified array. Does not change state.
     * 
     * @param task task to add
     * @param groups list of groups
     * @returns list of groups with the task added
     */
    private addTask(task: Task, groups: Group[]) {
        for (let i = 0; i < groups.length; i++) {
            let group: Group = groups[i];
            if (task.props.groupId === group.props.groupId) {
                group = Group.addTask(group, task);
                groups[i] = group;
                break;
            }
        }
        return groups;
    }

    /**
     * Remove a task from the groups and return the modified array. Does not change state
     * 
     * @param taskId the id of the task to remove
     * @param groups groups to remove the task from
     * @returns list of groups with the task removed
     */
    private removeTask(taskId: string, groups: Group[]) {
        // find task and remove it
        for(let i = 0; i < groups.length; i++) {
            let group: Group = this.state.groups[i];
            if (Group.containsTask(group, taskId)) {
                group = Group.removeTask(group, taskId);
                groups[i] = group;
                break;
            }
        }
        return groups;
    }

    private addGroup(group: Group) {
        if (group.props.isArchived) {
            let groups = [...this.state.archivedGroups];
            groups.push(group);
            this.setState({archivedGroups: groups});
        } else {
            let groups = [...this.state.groups];
            groups.push(group);
            this.setState({groups: groups});
        }
    }

    private removeGroup(groupId: string) {
        if (this.props.archived) {
            let groups = [...this.state.archivedGroups];
            for (let i = 0; i < groups.length; i++) {
                if (groups[i].props.groupId === groupId) {
                    groups.splice(i, 1);
                }
            }
            this.setState({archivedGroups: groups});
        } else {
            let groups = [...this.state.groups];
            for (let i = 0; i < groups.length; i++) {
                if (groups[i].props.groupId === groupId) {
                    groups.splice(i, 1);
                }
            }
            this.setState({groups: groups});
        }
    }

    render() {
        if ((!this.props.archived && this.state.groups === undefined) ||
            (this.props.archived && this.state.archivedGroups === undefined)) {
            // loading
            this.fetchGroups();
            return (
                <Loading />
            );
        } else {
            let groups = this.props.archived ? this.state.archivedGroups : this.state.groups;
            groups.sort((a: Group, b: Group) =>
                {return a.props.position - b.props.position;}
            );
            groups = [...groups];
            for(let i = 0; i < groups.length; i++) {
                groups[i] = <Group {...groups[i].props} index={i} key={groups[i].props.groupId}/> as unknown as Group;
            }
            let ungroupedGroup: Group = undefined;
            if (groups.length > 0 && groups[groups.length - 1].props.groupId === "ungrouped") {
                ungroupedGroup = groups[groups.length - 1];
                groups.splice(groups.length - 1, 1);
            }
            return (
                <div id="groups-view" className="groups-view">
                    <DragDropContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
                        <Droppable droppableId={"group-droppable"} direction="grid" isDropDisabled={!this.state.enableGroupDragging}>
                            {provided => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    <div className="group-tasks">
                                        {groups.length > 0 &&
                                            <>
                                                {groups}
                                            </>
                                        }
                                        {groups.length === 0 &&
                                            <div className="empty-group-container">
                                                {this.props.archived &&
                                                    <div>Looks like you don't archived any groups yet!</div>
                                                }
                                                {!this.props.archived &&
                                                    <>
                                                        <div>Looks like you don't have any groups yet... you can add one to get started!</div>
                                                        <AddGroupButton onAddGroup={this.onAddGroup} notFixedPosition={true}/>
                                                    </>
                                                }
                                            </div>
                                        }
                                        {provided.placeholder}
                                    </div>
                                    {ungroupedGroup &&
                                        <div className="ungrouped-tasks">
                                            <Ungrouped
                                                group={ungroupedGroup}
                                                onNewTask={this.onNewTask}
                                                disableTaskDragging={this.state.enableGroupDragging}
                                            />
                                        </div>
                                    }
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {!this.props.archived && 
                        <AddGroupButton onAddGroup={this.onAddGroup} />
                    }
                </div>   
            );
        }
    }

    async fetchGroups() {
        let viewType = this.props.archived ? "archivedGroups" : "groups";
        let data = await makeRequest({action: "FetchTasks", viewType: viewType});
        let groups = [];
        for(let i = 0; i < data.length; i++) {
            let groupJson = data[i];
            let group = this.groupFromJson(groupJson);
            groups.push(group);
        }
        if (this.props.archived) {
            this.setState({archivedGroups: groups});
        } else {
            this.setState({groups: groups});
        }
    }

    groupFromJson(groupJson: any) : Group {
        let tasks: Task[] = [];
        if (groupJson.tasks) {
            for(let i=0; i < groupJson.tasks.length; i++) {
                tasks.push(
                    Task.createTaskFromJson(
                        groupJson.tasks[i],
                        i,
                        this.onTaskUpdate,
                        this.onTaskDelete,
                        undefined,
                        groupJson.color
                    )
                );
            }
        }
        return (<Group
            groupId={groupJson.groupID}
            name={groupJson.name}
            position={groupJson.position}
            color={groupJson.color}
            showCompleted={groupJson.showCompleted}
            isArchived={groupJson.isArchived}
            tasks={tasks}
            onNewTask={this.onNewTask}
            onGroupChange={this.onGroupUpdate}
            onArchive = {this.onGroupArchive}
            onDeletion = {this.onGroupDeletion}
        /> as unknown as Group);
    }

}

export default GroupsView;
